module.exports = {
    graphQlUrl: "https://admin.server.devagil.com/comercio-admin-backend/admin/graphql",
    backendUrl: "https://admin.server.devagil.com/comercio-admin-backend/",
    // graphQlUrl: "http://localhost:5000/admin/graphql",
    // backendUrl: "http://localhost:5000/",
    LAMBDA_AUTH: "https://lambda.devagil.com/dev-auth",
    LAMBDA_ADMIN: "https://lambda.devagil.com/dev-admin", // deprecando...
    LAMBDA_ALIADOS_13489AA: "https://lambda.devagil.com/aliados-13489AA",
    LAMBDA_LINCE: 'https://lambda.devagil.com/lince',
    LAMBDA_COMMON: "https://lambda.devagil.com/dev-common",
    LAMBDA_POS: "https://lambda.devagil.com/dev-pos",
    LAMBDA_SHOP: "https://lambda.devagil.com/dev-shop",
    AppKey: 'aece4bc5db1d757be2cc2b2416f08299',
    posFrontUrl: "https://pos.devagil.com/",
    API_KEY_MAPS: 'AIzaSyCETkB78xH2peosxbPxCW7fiHxg0jxAXoM',
    AUTH_URL: "https://account.devagil.com",
    TINTY_MCE: "owiocz2hm60g3svswoaqir51ifhobqcd41iycbl1h4osao2a",
    SOCKET_URL: "https://socket.server.devagil.com/",
    LAMBDA_MARKET: "https://lambda.devagil.com/market",
    LAMBDA_SUPER: "https://lambda.devagil.com/dev-super",
    LAMBDA_API: "https://lambda.devagil.com/dev-agil",
};
